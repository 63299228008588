'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.popUp = function popUp() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $popUp = document.querySelector('.pop-up-wrapper');
    var $popUpOverlay = document.querySelector('.pop-up-overlay');
    var $popUpClose = document.querySelector('.pop-up-close');
    var $popUpOpen = document.querySelector('.pop-up-sentence');

    if (localStorage.getItem('pop-up', 'close')) {
      $popUpOverlay.classList.add('is-none');
      $popUp.classList.add('is-none');
    }

    if ($popUp && $popUpOverlay) {
      console.log('pop-up');

      $popUpOverlay.addEventListener('click', function (e) {
        $popUpOverlay.classList.add('is-none');
        $popUp.classList.add('is-none');
        localStorage.setItem('pop-up', 'close');
      });

      $popUpClose.addEventListener('click', function (e) {
        $popUpOverlay.classList.add('is-none');
        $popUp.classList.add('is-none');
        localStorage.setItem('pop-up', 'close');
      });

      $popUpOpen.addEventListener('click', function (e) {
        $popUpOverlay.classList.remove('is-none');
        $popUp.classList.remove('is-none');
        localStorage.removeItem('pop-up');
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();