'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.VipForm = function VipForm() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $formSections = [].slice.call(document.querySelectorAll('.form-parts'));
    var $nextButton = document.querySelector('[data-next-page]');
    var $prevButton = document.querySelector('[data-prev-page]');
    var $form = document.querySelector('#_form_1_');
    var $vipForm = document.querySelector('#vipFormContainer');
    var $vipButton = document.querySelector('.vip-scroll');
    var $tailDatetimeFields = [].slice.call(document.querySelectorAll('.tail-datetime-field'));

    if (!$form) {
      return false;
    }

    window.addEventListener('scroll', function (e) {

      if ($vipForm && $vipButton) {
        if (isInViewport($vipForm)) {
          $vipButton.classList.add('is-hidden');
        } else if (!isInViewport($vipForm)) {
          $vipButton.classList.remove('is-hidden');
        }
      }
    });

    document.addEventListener("DOMContentLoaded", function () {
      tail.DateTime($tailDatetimeFields, { locale: 'fr', timeHours: false, timeMinutes: false, timeSeconds: false, timeFormat: '' });
    });

    $nextButton.addEventListener('click', function (e) {

      var $pageText = document.querySelector('[data-form-page]');
      var $currentSection = document.querySelector('.form-parts.is-active');

      var currentIndex = parseInt($currentSection.getAttribute('data-form-index'));
      var nextIndex = currentIndex + 1;

      var $requiredFields = $formSections[currentIndex - 1].querySelectorAll('[required]');
      var isValidated = true;

      for (i = 0; i < $requiredFields.length; i++) {
        var $currentField = $requiredFields[i];

        if ($currentField.value.length == 0 || $currentField.type == 'email' && !validateEmail($currentField.value)) {
          $currentField.classList.add('has-error');
          isValidated = false;
        } else {
          $currentField.classList.remove('has-error');
        }
      }

      if (!isValidated) return false;

      if (nextIndex == 4) {
        console.log('is at final step');
        $nextButton.innerHTML = 'Envoyer';
      }

      if (nextIndex == 5) {
        $form.submit();
      }

      if (nextIndex < 5) {
        $prevButton.style.display = 'inline-block';

        for (i = 0; i < $formSections.length; i++) {
          $formSections[i].classList.remove('is-active');
        }

        var $nextSection = document.querySelector('[data-form-index="' + nextIndex + '"]');
        $nextSection.classList.add('is-active');

        $pageText.innerHTML = nextIndex + '/4';
      }
    });

    $prevButton.addEventListener('click', function (e) {

      var $pageText = document.querySelector('[data-form-page]');
      var $currentSection = document.querySelector('.form-parts.is-active');

      var currentIndex = parseInt($currentSection.getAttribute('data-form-index'));
      var prevIndex = currentIndex - 1;

      console.log(prevIndex);

      if (prevIndex <= 3) {
        $nextButton.innerHTML = 'Suivant';
        $nextButton.type = 'button';
      }

      if (prevIndex == 1) {
        $prevButton.style.display = 'none';
      }

      if (prevIndex > 0) {

        for (i = 0; i < $formSections.length; i++) {
          $formSections[i].classList.remove('is-active');
        }

        var $nextSection = document.querySelector('[data-form-index="' + prevIndex + '"]');
        $nextSection.classList.add('is-active');

        $pageText.innerHTML = prevIndex + '/4';
      }
    });

    return true;
  };

  var validateEmail = function validateEmail(email) {
    return (/^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(email)
    );
  };

  var isInViewport = function isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();

    return bounding.top >= 0 && bounding.bottom <= (window.innerHeight + elem.offsetHeight / 2 || document.documentElement.clientHeight + elem.offsetHeight / 2);
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();